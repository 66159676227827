const Footer = () => {

    return (

        <div className="footerSection">
            <div className="wrapper">
                <p>Made by Wilson Choi Copyright&copy; 2023. Created at Juno College of Technology</p>
            </div>
        </div>

    );
};

export default Footer;