const Header = () => {

    return (

        <div className="header">
            <div className="wrapper">
                <h1>Card-Style Pokédex!</h1>
            </div>
        </div>

    );
};

export default Header;